import React from "react"
import Websitenewsizebanner from "../components/assets/images/Websitenewsizebanner.png"



import { About } from "./About"
import { Courses } from "./Courses"
import { Instructor } from "./Instructor"
import { Blog } from "./Blog"
import EnquiryForm from "./EnquiryForm"

export const Home = () => {
  return (
    <>
      <HomeContent />
      <About />
      <br />
      <br />
      <br />
      <Courses />
      <EnquiryForm/>
      <Instructor />
      <Blog />
    </>
  )
}

export const HomeContent = () => {
  return (
    <><section className="relative bg-secondary h-auto w-full">
  <div className="relative w-full h-auto flex justify-center items-center">
    {/* Banner Image */}
    <img
      src={Websitenewsizebanner}  // Replace with websitenewsizebanner for the responsive image
      alt="Banner with Text"
      className="object-contain w-full h-auto"  // Ensure image maintains aspect ratio and scales
      style={{ objectPosition: "center" }}  // Keeps the image centered
    />

    {/* Banner Text */}
    
  </div>
</section>


    </>
  )
}
