import React from "react"
import { FaGraduationCap, FaUsers } from "react-icons/fa"
import { GiEvilBook, GiWorld } from "react-icons/gi"
import { placement } from "../components/assets/data/dummydata"

export const Instructor = () => {
  return (
    <>
      <section className='instructor mb-16'>
        <div className='container'>
          <div className='heading py-12 text-center w-2/3 m-auto md:w-full'>
            <h1 className='text-3xl font-semibold text-black'>Placement</h1>
            <span className='text-[14px] mt-2 block'>
              At TechCloud, we are not just a training institute; we are your gateway to a successful career in the tech industry. With our specialized programs in cloud computing, software development, data science, and more, we provide the skills and knowledge that today's leading employers are looking for.

Our mission is to equip our students with the practical, hands-on experience needed to excel in the fast-paced world of technology. Through our expert-led training and real-world projects, we help students become job-ready and confident in their ability to take on industry challenges.
            </span>
          </div>
          <div className='content grid grid-cols-2 gap-5 md:grid-cols-1'>
            {placement.map((item, index) => (
              <div key={index} className='images rounded-lg relative overflow-hidden h-72 w-full before:top-0 before:left-0 before:content before:z-10'>
                <img src={item.cover} alt={`placement-image-${index}`} className='rounded-t-lg object-cover w-full h-72' />
                
              </div>
            ))}
          </div>
          <div className='content'>
            <div className='heading py-12 text-center w-2/3 m-auto md:w-full'>
              <h1 className='text-3xl font-semibold text-black'>We Are Proud</h1>
              <span className='text-[14px] mt-2 block'>You don't have to struggle alone, you've got our assistance and help.</span>
            </div>
            <div className='content grid grid-cols-4 gap-5 md:grid-cols-2'>
              <InstructorCard color='text-red-500' icon={<FaUsers size={40} />} title='500+' desc='Students Enrolled' />
              <InstructorCard color='text-orange-500' icon={<GiEvilBook size={40} />} title='50+' desc='Total Courses' />
              <InstructorCard color='text-purple-500' icon={<FaGraduationCap size={40} />} title='500+' desc='Online Learners' />
              <InstructorCard color='text-indigo-500' icon={<GiWorld size={40} />} title='500+' desc='Offline Learners' />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export const InstructorCard = (props) => {
  return (
    <div className={`box p-5 py-5 rounded-md`}>
      <div className={`${props.color}`}>{props.icon}</div>
      <div className='text mt-2'>
        <h4 className='text-lg font-semibold text-black'>{props.title}</h4>
        <p className='text-[15px]'>{props.desc}</p>
      </div>
    </div>
  )
}
