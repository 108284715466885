import React from "react"
import aboutImg from "../components/assets/images/about.jpg"
import aboutImgBanner from "../components/assets/images/about-banner.jpg"
import imgs from "../components/assets/images/join1.png"

import { AiOutlineCheck } from "react-icons/ai"

export const About = () => {
  return (
    <>
      <section className='about py-16'>
        <div className='container'>
          <div className='heading text-center py-12'>
            <h1 className='text-3xl font-semibold text-black'>About Us</h1>
            <span className='text-sm mt-2 block'> Tech Cloud: Pune's #1 Destination for Quality and Affordable
 Advanced IT Training</span>
	<span className='text-sm mt-2 block'>At Tech Cloud, we pride ourselves on being Pune's leading destination
 for quality and affordable advanced IT training. Our mission is to
 empower aspiring professionals with in-demand skills through
 comprehensive programs designed to meet industry standards.
 Whether you're starting your career or upgrading your expertise, our
 expert-led courses, live projects, and hands-on learning ensure you're
 ready to tackle real-world challenges. Join us to unlock your potential
 and step confidently into a thriving tech career—all without breaking
 the bank. Experience excellence in IT education with Tech Cloud!
</span>
          </div>
          
        </div>
      </section>
      <AboutContent />
    </>
  )
}
export const AboutCard = (props) => {
  return (
    <div className={`box shadow-md p-5 py-8 rounded-md text-white ${props.color} cursor-pointer transition ease-in-out delay-150 hover:-translate-y-4 duration-300 `}>
      <div className='icon'>{props.icon}</div>
      <div className='text mt-5'>
        <h4 className='text-lg font-semibold my-3'>{props.title}</h4>
        <p className='text-sm'>{props.desc}</p>
      </div>
    </div>
  )
}

export const AboutContent = () => {
  return (
    <section className='mb-16'>
      <div className='container flex md:flex-col'>
        <div className='left w-1/3 md:w-full mr-8 md:mr-0 relative'>
          <img src={aboutImg} alt='aboutImg' className=' rounded-xl' />
          <img src={aboutImgBanner} alt='aboutImg' className='rounded-xl absolute -bottom-14 -left-24 h-56 md:left-80' />
          <div className='img-group ml-24 mt-3'>
            <img src={imgs} alt='' />
            <span className='text-[14px]'>
              Join over <label className='text-black text-sm'>1,000+</label> students
            </span>
          </div>
        </div>
        <div className='right w-2/3 md:w-full md:mt-16'>
          <div className='heading w-4/5 md:w-full'>
            <h1 className='text-3xl font-semibold text-black'>What makes Tech Cloud your best choice for IT training?</h1>
            <span className='text-sm mt-2 block leading-6'>20+ Years of MNC Expert Trainers
</span>
            <ul className='my-5'>
              <li className='text-sm flex items-center gap-5'>
                <AiOutlineCheck className='text-green-500' /> Quality Affordable Learning
              </li>
              <li className='text-sm flex items-center gap-5 my-2'>
                <AiOutlineCheck className='text-green-500' />
                Online & Offline Classrooms
              </li>
              <li className='text-sm flex items-center gap-5'>
                <AiOutlineCheck className='text-green-500' />
                Job Oriented Courses
              </li>
              <li className='text-sm flex items-center gap-5'>
                <AiOutlineCheck className='text-green-500' />
                Certificate with Internship

              </li>
              <li className='text-sm flex items-center gap-5'>
                <AiOutlineCheck className='text-green-500' />
                Live Training Sessions
              </li>
              <li className='text-sm flex items-center gap-5'>
                <AiOutlineCheck className='text-green-500' />
                Weekly Mock Tests / Interviews Preparation
              </li>
              <li className='text-sm flex items-center gap-5'>
                <AiOutlineCheck className='text-green-500' />
                100% Placements Assistance
              </li>
              <li className='text-sm flex items-center gap-5'>
                <AiOutlineCheck className='text-green-500' />
                Working on Real Time Projects

              </li>
            </ul>
            <button className='px-5 py-2 border border-gray-300 rounded-md text-sm'>Apply Now</button>
          </div>
        </div>
      </div>
    </section>
  )
}
