export const LinkData = [
  {
    id: 1,
    title: "Home",
    url: "/",
  },
  {
    id: 2,
    title: "About",
    url: "/about",
  },
  {
    id: 3,
    title: "Courses",
    url: "/courses",
  },
  {
    id: 4,
    title: "Placement",
    url: "/Instructor",
  },
  {
    id: 5,
    title: "Blog",
    url: "/blog",
  },
  {
    id: 6,
    title: "Enquire Us",
    url: "/ContactForm",
  },
];

export const courses = [
  {
    id: 1,
    cover: "../images/c c++.jpg",
    title: "C Programming Language - Course Module ",
    price: 'INR10000',
    pdf: "../images/pdf/C Programming Language - Course Module .pdf" // Add the PDF field
  },
  {
    id: 2,
    cover:  "../images/c c++.jpg",
    title: "C++ Programming Language - Course Module",
    price: 'INR10000',
    pdf: "../images/pdf/C++ Programming Language - Course Module.pdf"
  },
  {
    id: 3,
    cover: "../images/data science.jpg",
    title: "Diploma in Data Science with Deep Advance Python - Course Module ",
    price: 'INR 22000',
    pdf: "../images/pdf/Diploma in Data Science with Deep Advance Python - Course Module .pdf"
  },
  {
    id: 4,
    cover: "../images/Web developement.jpg",
    title: "MeanStack Development - Course Module ",
    price: 'INR15000',
    pdf: "../images/pdf/MeanStack Development - Course Module .pdf"
  },
  {
    id: 5,
    cover: "../images/AWS devop.jpg",
    title: "AWS Solution Architect - Course Module",
    price: 'INR22000',
    pdf: "../images/pdf/AWS Solution Architect - Course Module.pdf"
  },
  {
    id: 6,
    cover: "../images/danc.jpg",
    title: "data analytics",
    price: 'INR22000',
    pdf: "../images/pdf/data analytics.pdf"
  },
  {
    id: 7,
    cover: "../images/dotnet.jpeg",
    title: "DoT_Net_-_Course_Module_2",
    price: 'INR22000',
    pdf: "../pdfs/DoT_Net_-_Course_Module_2.pdf"
  },
  {
    id: 9,
    cover: "../images/Mysql.jpeg",
    title: "MySQL",
    price: 'INR15000',
    pdf: "../images/pdf/course6.pdf"
  },
];
export const placement = [
{
    id: 1,
    cover: "../images/2.jpg",
  },
{
    id: 2,
    cover: "../images/3.jpg",
  },
{
    id: 3,
    cover: "../images/4.jpg",
  },
{
    id: 4,
    cover: "../images/5.jpg",
  },
{
    id: 5,
    cover: "../images/6.jpg",
  },


];